.carousel {
    overflow: hidden;
  }
  
  .inner {
    white-space: nowrap;
    transition: transform .9s fade;
  }
  
  .carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  /* #stage a:nth-of-type(1) {
    animation-name: fader;
    animation-delay: 4s;
    animation-duration: 1s;
    z-index: 20;
  }
  #stage a:nth-of-type(2) {
    z-index: 10;
  }
  #stage a:nth-of-type(n+3) {
    display: none;
  } */

  @keyframes fade {
    from { opacity: 2.0; }
    to   { opacity: 0.0; }
  }