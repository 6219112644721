@font-face {
    font-family: "Poppins";
    src: local("Poppins"),url("../font/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
    font-display:  swap;
}
@font-face {
    font-family: "PoppinsBold";
    src: local("Poppins"),url("../font/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: normal;
    font-display:  swap;
}
@font-face {
    font-family: "FibonSans";
    src: local("FibonSans"),url("../font/FibonSans/FibonSans-Regular.otf") format("opentype");
    font-weight: normal;
    font-display:  swap;
}

body {
    margin: 0;
    font-family: Poppins;
}
h1, h2, h3 {
    font-family: PoppinsBold;
}
:root {
    --white: #ffff;
    --light-green: #06CF99;
    --dark-green: #073B4C;
    --light-gray: #6C6C6C; 
    --dark-gray: #222222;
    
}
/* .rotate {
        animation-name: rotate;       
        animation-duration: 4s;       
        animation-iteration-count: infinite;      
        animation-timing-function: linear;  

        @keyframes rotate { 
            from {transform: rotate(0deg);}  
            to {transform: rotate(360deg);}  
        }
} */







