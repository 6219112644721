/* FLOATING BUBBLES */
.from-top {    
    position: absolute;
    top: 0;
    left: 0 ;
}
.to-halfway li{    
    position: absolute;    
    bottom: -180px;
}

.from-halfway {    
    position: absolute;
    top: 68rem;
    left: 0;
}
.to-bottom li{
    position: absolute;    
    bottom: -200px;
}

.floating-bubbles {
    width: 97%;
    height: 100vh;
    z-index: -2;
    transform: translateZ(0);
}

.floating-bubbles li {
    list-style: none;
    display: block;
    border-radius: 50%;
    animation: square 20s infinite;
    transition-timing-function: linear;
}

.floating-bubbles li:nth-child(1){
    width: 6px;
    height: 6px;
    background: #1183AB;
    left: 10%;    
    animation-delay: 3s;
    animation-duration: 15.5s;
}
.floating-bubbles li:nth-child(2) {
    width: 20px;
    height: 20px;
    background: #06CF99;
    left: 30%;
    animation-delay: 1.7s;
    animation-duration: 18s;
}
.floating-bubbles li:nth-child(3) {
    width: 13px;
    height: 13px;
    background: #FFCA66;
    left: 35%;    
    animation-delay: 4s;
    animation-duration: 21s;
}
.floating-bubbles li:nth-child(4) {
    width: 10px;
    height: 10px;
    background: #1183AB;
    left: 18%;    
    animation-delay: 2.5s;
    animation-duration: 26s;
}

.floating-bubbles li:nth-child(5) {
    width: 13px;
    height: 13px;
    background: #FFCA66;
    left: 37%;    
    animation-delay: 3s;
    animation-duration: 18s;
}
.floating-bubbles li:nth-child(6) {
    width: 6px;
    height: 6px;
    background: #1183AB;
    left: 14%;    
    animation-delay: 2s;
    animation-duration: 16s;
}
.floating-bubbles li:nth-child(7) {
    width: 20px;
    height: 20px;
    background: #06CF99;
    left: 52%;
    animation-delay: 4s;
    animation-duration: 21s;
}
.floating-bubbles li:nth-child(8) {
    width: 10px;
    height: 10px;
    background: #1183AB;
    left: 25%;    
    animation-delay: 3.5s;
    animation-duration: 15s;
}
.floating-bubbles li:nth-child(9) {
    width: 13px;
    height: 13px;
    background: #073B4C;
    left: 10%;    
    animation-delay: 1.7s;
    animation-duration: 20s;
}
.floating-bubbles li:nth-child(10) {
    width: 12px;
    height: 12px;
    background: #073B4C;
    left: 45%;    
    animation-delay: 1.7s;
    animation-duration: 22s;
}
.floating-bubbles li:nth-child(11) {
    width: 13px;
    height: 13px;
    background: #FFCA66;
    left: 67%;    
    animation-delay: 3s;
    animation-duration: 18s;
}
.floating-bubbles li:nth-child(12) {
    width: 6px;
    height: 6px;
    background: #1183AB;
    left: 77%;    
    animation-delay: 2.9s;
    animation-duration: 21s;
}
.floating-bubbles li:nth-child(13) {
    width: 6px;
    height: 6px;
    background: #FFCA66;
    left: 37%;    
    animation-delay: 4.5s;
    animation-duration: 22s;
}

@keyframes square {
    0% {        
        -webkit-transform: translateY(-1080px) rotate(630deg);
        transform: translateY(-1080px) rotate(630deg);
    }
    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }
}

